<template>
  <div class="documentDetail">
    <div class="question">
      <h2>{{ question }}</h2>
    </div>
    <div class="answer">
      <div v-html="answer" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      answer: "",
      question: "",
    };
  },
  mounted() {
    this.initEditorData();
  },
  methods: {
    initEditorData() {
      this.$request
        .getHelpDocumentInfo({
          id: this.$route.query.id,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          const { question, answer } = res?.data || {};
          this.question = question;
          this.answer = answer;
        });
    },
  },
};
</script>

<style lang="scss">
.documentDetail {
  background-color: #fff;
  padding: 24px 14%;
  .question {
    text-align: center;
  }
}
</style>